/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import Header from "../../common/components/header";
import { Icon } from "@iconify/react";
import "./css/index.css";
import { useEffect, useState } from "react";
import { getContactFetch } from "../../common/components/footer/API";

export default function Contract({ authorized, firebase, user }) {
  const [contactData, setContactData] = useState();

  const getContactData = async () => {
    const res = await getContactFetch();
    setContactData(res);
  };

  useEffect(() => {
    getContactData();
  }, []);
  function formatPhoneNumber(number) {
    // Remove any non-numeric characters
    const cleanedNumber = number.replace(/\D/g, "");

    // Add the country code for Thailand (+66)
    return `+66${cleanedNumber.substring(1)}`;
  }

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#303030" }}>
      <Header firebase={firebase} user={user} />
      <div
        style={{
          paddingTop: 62,
          paddingBottom: 74,
        }}
      >
        <h2
          style={{
            fontSize: 40,
            color: "#FEE285",
            textAlign: "center",
            marginBottom: 37,
          }}
        >
          ติดต่อเรา
        </h2>
        <div
          style={{
            marginInline: "auto",
            maxWidth: "75rem",
            fontSize: 24,
            color: "#FFFFFF",
          }}
        >
          <p style={{ fontSize: 24 }}>{contactData?.slogan}</p>
          <p style={{ marginTop: 37, display: "flex", alignItems: "center" }}>
            <Icon
              icon="mdi:address-marker"
              style={{ width: 32, height: 32, color: "#F3BB02" }}
            />
            <span style={{ fontSize: 24 }}>{contactData?.address}</span>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginTop: 24,
            }}
            onClick={() => {
              window.open(`tel:${formatPhoneNumber(contactData?.phone)}`);
            }}
          >
            <div
              style={{
                height: 97,
                width: 87,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F3BB02",
                borderRadius: "16px 0px 0px 16px",
              }}
            >
              <img
                src={"/assets/images/footer/phone-call.png"}
                style={{ height: 60, padding: 10 }}
                alt="witte-bangkok"
              />
            </div>

            <div
              style={{
                height: 97,
                minWidth: 180,
                backgroundColor: "#FFF",
                borderRadius: "0px 16px 16px 0px",
              }}
            >
              <div style={{ padding: 12 }}>
                <label
                  style={{
                    fontSize: 24,
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  โทร
                </label>
                <br />
                <label
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  {contactData?.phone}
                </label>
              </div>
            </div>
          </div>
          <div className="social-line">
            <a href={contactData?.facebook} target="_blank">
              <div className="facebook-icon">
                <Icon
                  icon="ri:facebook-fill"
                  width="1.2em"
                  height="1.2em"
                  style={{
                    color: "white",
                    width: "45px",
                    height: "45px",
                  }}
                />
              </div>
            </a>
            <a href={contactData?.line} target="_blank">
              <div className="line-icon">
                <Icon
                  icon="mingcute:line-app-fill"
                  style={{ color: "white", width: "50px", height: "50px" }}
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
