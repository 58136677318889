import React, { useEffect, useState } from "react";
import "./css/index.css";
import { Col, Empty, Pagination, Row } from "antd";
import LoadingPage from "../../../../common/components/loading/page";
import { serverUrl } from "../../../../constants";
import parse from "html-react-parser";
import axios from "axios";

// Testimonial Card Component
const TestimonialCard = ({ name, avatar, message }) => {
  const [imageSrc, setImageSrc] = useState(avatar);

  const handleError = () => {
    setImageSrc("/assets/images/default/profile.jpg");
  };

  return (
    <div className="testimonial-card">
      <div className="testimonial-header">
        <img
          src={imageSrc}
          alt={`${name}'s avatar`}
          className="testimonial-avatar"
          onError={handleError}
        />
        <h3 className="testimonial-name">{name}</h3>
      </div>
      <span className="testimonial-message">{parse(message)}</span>
    </div>
  );
};

const UserReview = () => {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [total, setTotal] = useState(0); // Total number of reviews
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const perPage = 2; // Reviews per page
  const [reviews, setReviews] = useState([]); // All reviews

  // Fetch all reviews from the server
  const getReviews = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${serverUrl}/api/v1/review/get-review-all`
      );
      const data = response.data;

      if (data.isSuccess) {
        const result = data.formData;
        setTotal(result.length); // Set total reviews for pagination
        setReviews(result); // Set all reviews
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching reviews:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReviews(); // Fetch reviews when component mounts
  }, []);

  if (isLoading) return <LoadingPage />;

  // Calculate the reviews to display based on the current page
  const currentReviews = reviews.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  const onChange = (page) => {
    setCurrentPage(page); // Update the current page when pagination changes
  };

  return (
    <div className="user-review">
      <div className="container">
        <h2 style={{ fontSize: 36, fontWeight: "bolder", textAlign: "center" }}>
          รีวิวจากผู้ใช้งาน
        </h2>
        <Row gutter={[24, 24]} style={{ marginTop: 36 }}>
          {currentReviews.length > 0 ? (
            currentReviews.map((review) => (
              <Col sm={24} md={12} key={review.id}>
                <TestimonialCard
                  name={review.user.username}
                  avatar={review.user.imageProfile}
                  message={review.massage}
                />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ textAlign: "center" }}
              />
            </Col>
          )}
        </Row>
        <Row className="paginate-container">
          <Pagination
            size="small"
            current={currentPage}
            total={total} // Total reviews count
            pageSize={perPage} // Reviews per page
            defaultPageSize={perPage} // Default page size
            onChange={onChange} // Handle page change
            showSizeChanger={false} // Disable changing page size
          />
        </Row>
      </div>
    </div>
  );
};

export default UserReview;
