import React, { useEffect, useState } from "react";
import { Button, Card, Col, Empty, Input, Pagination, Row } from "antd";

import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router";
import LoadingPage from "../../../../common/components/loading/page";
import { getPredictionAllFetch } from "../../API";
import { serverUrl } from "../../../../constants";
import { useDimensions } from "../../../../common/mamager/ScreenManager";

const { Search } = Input;

const ProductList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(6);
  const { width } = useDimensions();

  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      try {
        const resultPrediction = await getPredictionAllFetch();
        // console.log("getPredictionAllFetch : ", resultPrediction);
        if (resultPrediction) {
          setProducts(resultPrediction);
          setTotal(resultPrediction.length);
        } else {
          setProducts([]);
          setTotal(0);
        }
      } catch (error) {
        // console.error("Error fetching getPredictionAllFetch:", error);
      }
      setIsLoading(false);
    };

    getProducts();
  }, []);

  const onSearch = (value) => {
    setKeyword(value);
    setCurrentPage(1); // Reset to page 1 when a new search is performed
  };

  const onChange = (page, pageSize) => {
    setCurrentPage(page); // Update current page
    setPerPage(pageSize); // Update page size
  };

  // Function to get filtered and paginated data
  const getFilteredProducts = () => {
    // Filter products based on the keyword (case-insensitive)
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(keyword.toLowerCase())
    );

    // Calculate the start and end index for pagination
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    return filteredProducts.slice(startIndex, endIndex);
  };

  const filteredProducts = getFilteredProducts();

  return (
    <div className="products">
      <section className="products-list">
        <div className="container">
          <div className="total-and-search">
            <div className="products-total">
              ผลการค้นหา ({filteredProducts.length} รายการ)
            </div>
            <Search
              placeholder="ชื่อโหร ออนไลน์"
              onSearch={onSearch}
              enterButton
              style={{ maxWidth: 340 }}
            />
          </div>
          {isLoading && <LoadingPage />}

          <Row
            gutter={[24, 24]}
            style={{ alignItems: "stretch", justifyContent: "center" }}
          >
            {filteredProducts.length > 0 &&
              !isLoading &&
              filteredProducts.map((data) => (
                <Col xs={24} sm={12} lg={8} key={data.predictionId}>
                  <div
                    className="wrapper"
                    onClick={() => {
                      if (width <= 440) {
                        navigate(`/prediction/${data.predictionId.toString()}`);
                        window.scroll(0, 0);
                      }
                    }}
                  >
                    <div className="front-card">
                      <Card
                        hoverable
                        style={{
                          fontSize: "18px",
                          padding: "0",
                          height: 400,
                          border: "2px solid #f0f0f0",
                        }}
                        cover={
                          <img
                            alt="prediction"
                            src={`${serverUrl}/${data.imgCoverUrl}`}
                            width={354}
                            height={252}
                            style={{
                              maxWidth: "100%",
                              objectFit: "cover",
                            }}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div
                              style={{
                                color: "black",
                                fontSize: "24px",
                                paddingInline: "0px",
                                marginTop: "6px",
                                height: 70,
                              }}
                            >
                              <div className="title">{data.title}</div>
                            </div>
                          }
                          description={
                            <div
                              style={{
                                color: "black",
                                padding: "0px",
                                display: "flex",
                                flexDirection: "column",
                                fontSize: 24,
                                marginTop: 4,
                              }}
                            >
                              <div className="price">
                                ราคา{" "}
                                <span
                                  style={{
                                    color: `${
                                      data.discount > 0 ? "black" : "red"
                                    }`,
                                    textDecoration:
                                      data.discount > 0
                                        ? "line-through"
                                        : "none",
                                    textDecorationColor:
                                      data.discount > 0 ? "red" : "transparent",
                                  }}
                                >
                                  {new Intl.NumberFormat("en-US").format(
                                    data.price
                                  )}
                                </span>{" "}
                                {data.discount > 0 && (
                                  <span>
                                    ลดเหลือ{" "}
                                    <span style={{ color: "red" }}>
                                      {new Intl.NumberFormat("en-US").format(
                                        data.price - data.discount
                                      )}
                                    </span>{" "}
                                  </span>
                                )}
                                บาท{data.discount === 0 && "/เดือน"}
                              </div>
                            </div>
                          }
                        />
                      </Card>
                    </div>
                    {width > 440 && (
                      <div className="overlay">
                        <div
                          className="text"
                          style={{
                            color: "white",
                            padding: 20,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <p className="detail-title">{data.title}</p>
                          <p className="detail">{data.subTitle}</p>
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                              navigate(
                                `/prediction/${data.predictionId.toString()}`
                              );
                              window.scroll(0, 0);
                            }}
                            style={{
                              marginTop: "auto",
                              width: "fit-content",
                            }}
                          >
                            รายละเอียดเพิ่มเติม
                          </Button>
                        </div>
                        <img
                          src="./assets/images/page/page_home/our_service_small_back_bg.jpg"
                          alt="back_background"
                          style={{
                            width: "100%",
                            height: 400,
                            objectFit: "cover",
                            zIndex: -1,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            {filteredProducts.length <= 0 && !isLoading && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ textAlign: "center" }}
              />
            )}
          </Row>
          {filteredProducts.length > 0 && !isLoading && (
            <Row className="paginate-container">
              <Pagination
                size="small"
                current={currentPage}
                total={total}
                pageSize={perPage}
                defaultPageSize={12}
                onChange={onChange}
                pageSizeOptions={["6", "12", "18", "24", "30"]}
              />
            </Row>
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductList;
