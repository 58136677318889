/* eslint-disable no-array-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Image,
  List,
  Modal,
  Row,
  Select,
  // Spin,
  Form,
  TimePicker,
  Upload,
  Divider,
  Popover,
  Steps,
} from "antd";
import { useState, useEffect, useRef } from "react";
import locale from "antd/es/date-picker/locale/de_DE";
import { Icon } from "@iconify/react";
import {
  useNavigate,
  // useLocation
} from "react-router-dom";
import * as moment from "moment";
import { Content } from "antd/lib/layout/layout";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import firebase from "firebase/app";
import "../payment/css/index.css";
import { apiServerUrl, imgDefaultUrl, serverUrl } from "../../constants";
import { useDimensions } from "../../common/mamager/ScreenManager";
import Header from "../../common/components/header";
import LoadingPage from "../../common/components/loading/page";
import Footer from "../../common/components/footer";
import { detectSignIn } from "../../common/mamager/DetectManager";
import { Notification } from "../../common/components/notification";
import { useDispatch, useSelector } from "react-redux";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { getPaymentAccountFetch, insertPaymentOrderFetch, lineNotifyPaymentOrder } from "./API";
import { v4 as uuidv4 } from "uuid";
import Video from "../../common/components/video";
// import { getUsageByIdFetch } from "../erp/manage/usage/API";

const { Option } = Select;

export default function Payment(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { resultBasketOrder } = useSelector(
    ({ basketOrderReducer }) => basketOrderReducer
  );

  const [paymentmethodLoading, setPaymentmethodLoading] = useState(false);

  const [imgTransferSlipURL, setImgTransferSlipURL] = useState({
    loading: false,
    imageUrl: null,
  });

  // const location = useLocation()
  // const params = new URLSearchParams(location.search)
  // const courseId = params.get("course-id")

  const [pamentTransferModal, setPamentTransferModal] = useState({
    isShow: false,
    title: null,
  });

  // const [course, setCourse] = useState(null)
  const [paymentAccount, setPaymentAccount] = useState(null);

  const dispatch = useDispatch();

  const [day, setDay] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);

  const [uid, setUid] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);

  //Remaing time for QR-code peyment
  const targetTime = new Date(new Date().getTime() + 15 * 60 * 1000); // 15 minutes in milliseconds
  // const targetTime = new Date(new Date().getTime());
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(targetTime)
  );

  const optionTransferSlipImage = {
    name: "file",
    action: `${apiServerUrl}/upload/file`,
    data: {
      bucket: "witte-bangkok",
      folder: "payment/slip/image",
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // console.log("INFO", info)
      if (info.file.status !== "uploading") {
        if (info.fileList.length > 0) {
          setImgTransferSlipURL({
            imageUrl: info.file.response.url,
            loading: false,
          });
        }
      } else {
        setImgTransferSlipURL({
          loading: true,
          imageUrl: imgTransferSlipURL.imageUrl,
        });
      }

      if (info.file.status === "done") {
        Notification(
          "success",
          "เเจ้งเตือน!",
          "อัพโหลดหลักฐานการชำระเงินสำเร็จ"
        );
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "ไม่สามารถอัพโหลดหลักฐานการชำระเงินได้ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const { width } = useDimensions();

  const genFormatPrice = (price) => {
    return Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(Number(price));
  };

  const getCourseById = async () => {
    // const resultCourse = await getCourseByIdFetch(courseId, language); // params is courseId, language
    // setCourse(resultCourse)
    // if (resultCourse) {
    // calculateNetPrice(resultCourse)
    // const resultCourseOwner = await getCourseOwnerFetch(resultCourse.uid)
    // setCourseOwner(resultCourseOwner)
    // setTimeLeft(calculateDiscountTimeLeft(resultCourse.discountDateEnd))
    // }
  };

  const detectCourseHasBeenPurchased = async (uid) => {
    // let obj = {
    //     learnerId: uid,
    //     courseId: courseId,
    //     isActive: 1,
    // }
    // const resultDetectCourseHasBeenPurchased = await getDetectCourseHasBeenPurchasedFetch(obj)
    // const resultPaymentOrder = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
    // if (resultDetectCourseHasBeenPurchased && resultPaymentOrder?.isPaid) {
    //     let calculateIsExpire = null
    //     if (resultDetectCourseHasBeenPurchased.endLearnDate) {
    //         calculateIsExpire = calculateDetectCourseExpire(resultDetectCourseHasBeenPurchased.endLearnDate)
    //         if (calculateIsExpire) {
    //             if (calculateIsExpire.isExpire) {
    //                 setIsExpire(true)
    //             } else {
    //                 redirectPageClassroomCourseStudy()
    //             }
    //         } else {
    //             redirectPageClassroomCourseStudy()
    //         }
    //     }
    //     if (!calculateIsExpire) {
    //         redirectPageClassroomCourseStudy()
    //     }
    // }
  };

  const getDay = () => {
    let tmpDay = [];
    for (let i = 1; i <= 31; i++) {
      tmpDay.push({
        label: i,
        value: i,
      });
    }
    setDay(tmpDay);
  };

  const getMonth = () => {
    let tmpMonth = [
      { label: "มกราคม", value: 1 },
      { label: "กุมภาพันธ์", value: 2 },
      { label: "มีนาคม", value: 3 },
      { label: "เมษายน", value: 4 },
      { label: "พฤษภาคม", value: 5 },
      { label: "มิถุนายน", value: 6 },
      { label: "กรกฎาคม", value: 7 },
      { label: "สิงหาคม", value: 8 },
      { label: "กันยายน", value: 9 },
      { label: "ตุลาคม", value: 10 },
      { label: "พฤศจิกายน", value: 11 },
      { label: "ธันวาคม", value: 12 },
    ];
    setMonth(tmpMonth);
  };

  const getYear = () => {
    let tmpYear = [];
    let a = moment().format("YYYY");
    let yearCurrent = Number(a) + 543 - 1;
    for (let i = yearCurrent; i > yearCurrent - 100; i--) {
      tmpYear.push({
        label: i + 1,
        value: i + 1,
      });
    }
    setYear(tmpYear);
  };

  const genSumCoursePrice = () => {
    let price = 0;
    let netPrice = 0;

    resultBasketOrder?.map((item) => {
      price = price + item?.price;
    });

    return {
      price,
      netPrice: price + 0, // ...
    };
  };

  // console.log("props.users : ", props.user.uid)
  // console.log("resultBasketOrder : ", resultBasketOrder)

  const detectProductCategory = (item) => {
    if (item.courseId && item.courseTypeId === 1) {
      // course
      return 1;
    }

    if (item.predictionId) {
      // prediction
      return 2;
    }

    if (item.courseId && item.courseTypeId === 2) {
      // exam certificate
      return 3;
    }

    return null;
  };

  const onFinish = async (values) => {
    let orderList = new Array();
    if (imgTransferSlipURL.imageUrl !== null) {
      let day = values.day;
      let month = values.month;
      let year = values.year;
      let date = Number(year) - 543 + "/" + month + "/" + day;
      let time = moment(String(values.time)).format("HH:mm");
      let dateTimeTransfer = moment(date + " " + time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let paymentOrderGroupId = uuidv4();
      let orderType = null
      let orderNetPrice = "-"
      let orderName = "-"

      resultBasketOrder?.map((item, index) => {
        // if (index === 0) {
        //   orderList.push({
        //     orderName:
        //       // (resultBasketOrder?.length > 0 ?
        //       //     item.title
        //       //     :
        //       `คำสั่งซื้อจำนวน ${resultBasketOrder?.length} รายการ`,
        //     // ),
        //     uid: props.user.uid,
        //     netPrice: Number(genSumCoursePrice().netPrice),
        //     paymentStatusId: 2,
        //     productCategoryId: null, // parent is don't have payment type
        //     transferSlipImageUrl: imgTransferSlipURL.imageUrl,
        //     dateTimeTransfer,
        //     isPaymentOrderParent: true,
        //     paymentOrderGroupId,
        //     courseId: null, // item.courseId,
        //     predictionId: null, // item.predictionId,
        //   });
        // }

        // set data for line notify
        orderName = item.title
        orderType = item.courseId ? 1 : (item.predictionId ? 2 : null)
        orderNetPrice = Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(item?.price) + " บาท"

        orderList.push({
          orderName: item.title,
          uid: props.user.uid,
          netPrice: item.price,
          paymentStatusId: 2,
          productCategoryId: detectProductCategory(item),
          transferSlipImageUrl: imgTransferSlipURL.imageUrl,
          dateTimeTransfer,
          isPaymentOrderParent: true, // default is true
          paymentOrderGroupId,
          courseId: item.courseId,
          predictionId: item.predictionId,
        });
      });
      // console.log("orderList : ", orderList)

      const result = await insertPaymentOrderFetch(orderList); // must be array!
      if (result.isSuccess) {
        setCurrentStep(2);
        setFormDefault();
        dispatch(setBasketOrderStateToSuccess([]));
        Notification("success", "ส่งหลักฐานการชำระเงินสำเร็จ");
        // navigate("/account?tab=paymentTransaction");

        // -----------------------------------------------------------------------

        let productType = "";
        switch (orderType) {
          case 1: productType = "คอร์สออนไลน์"; break;
          case 2: productType = "โหรออนไลน์"; break;
          default: productType = "-"; break;
        }
        
        let objLineNotify = {
          orderName,
          orderNetPrice,
          productType,	
          orderStatus: "รอการตรวจสอบชำระเงิน",
          orderDateTime: dateTimeTransfer,
          orderPaymentMethodType: "โอนเงิน",
        }
        await lineNotifyPaymentOrder(objLineNotify)

        // -----------------------------------------------------------------------
      } else {
        Notification(
          "error",
          "ไม่สามารถส่งหลักฐานการชำระเงินสำเร็จได้ กรุณาลองใหม่อีกครั้ง"
        );
      }
    } else {
      Notification("warning", "เเจ้งเตือน!", "กรุณาอัพโหลด หลักฐานการโอนเงิน");
    }
    window.scroll(0, 0);
  };

  const setFormDefault = (type) => {
    form.resetFields();

    // ser default image
    setPamentTransferModal({
      isShow: false,
      title: null,
    });
  };

  useEffect(() => {
    if (resultBasketOrder?.length <= 0) {
      navigate("/course");
    }
  }, []);

  function calculateRemainingTime(targetTime) {
    const now = new Date();
    const timeDiff = targetTime - now;

    if (timeDiff <= 0) return { minutes: 0, seconds: 0 };

    const minutes = Math.floor(timeDiff / (1000 * 60)); // Calculate remaining minutes
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000); // Calculate remaining seconds

    return { minutes, seconds };
  }

  // useEffect(() => {
  //   if (currentStep !== 1) {
  //     setRemainingTime(calculateRemainingTime(targetTime));
  //     return;
  //   }
  //   const interval = setInterval(() => {
  //     setRemainingTime(calculateRemainingTime(targetTime));
  //   }, 1000);

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, [currentStep]);

  const genOrderedList = () => {
    return (
      <Card
        bordered
        style={{
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 4px 8px 0px #00000040",
        }}
      >
        <Row>
          <Col span={24}>
            {resultBasketOrder?.map((item, index) => {
              return (
                <Row>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      //   paddingBottom: 24,
                    }}
                  >
                    <img
                      src={`${serverUrl}/${item?.imgCoverUrl}`}
                      width={142}
                      height={102}
                      style={{
                        width: "100%",
                        top: 0,
                        left: 0,
                        borderRadius: 8,

                        marginRight: 12,
                      }}
                    />
                  </Col>

                  <Col
                    xs={18}
                    xl={18}
                    style={{ paddingLeft: width < 1200 ? 0 : 24 }}
                  >
                    <Row>
                      <Col span={24}>
                        <label
                          className="payment-order-title"
                          style={{ fontWeight: 600, fontSize: 20 }}
                        >
                          {item?.title}
                        </label>
                      </Col>
                      {/* <Col span={24}>
                        <label
                          className="payment-order-subtitle"
                          style={{ fontSize: 20 }}
                        >
                          {item?.subTitle}
                        </label>
                      </Col> */}
                      <Col span={24}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: 6,
                          }}
                        >
                          <label style={{ fontSize: 20 }}>
                            ราคา{" "}
                            {Intl.NumberFormat("en", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(item?.price)}{" "}
                            บาท
                          </label>

                          {/**Delete coures button */}
                          {/* <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                          
                              let tmpArr = resultBasketOrder.filter(
                                (fill) => fill.courseId !== item.courseId
                              );
                              dispatch(setBasketOrderStateToSuccess(tmpArr));
                              if (resultBasketOrder?.length === 1) {
                                navigate("/course");
                              }
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Icon
                                icon="ph:trash-bold"
                                style={{
                                  color: "white",
                                  height: 16,
                                  width: 16,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {resultBasketOrder?.length !== index + 1 ? (
                    <Col span={24}>
                      <Divider style={{ marginTop: 11, marginBottom: 15 }} />
                    </Col>
                  ) : (
                    []
                  )}
                </Row>
              );
            })}
          </Col>

          {/* <Col span={12} style={{ borderTop: "2px solid #FF7F00" }}>
            <div style={{ paddingTop: 15 }}>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingRight: 10,
                  fontSize: 18,
                  float: "right",
                }}
              >
                {resultBasketOrder.length > 0
                  ? "ราคาคอร์สทั้งหมด"
                  : "ราคาคอร์ส"}
              </label>
            </div>
          </Col>
          <Col span={12} style={{ borderTop: "2px solid #FF7F00" }}>
            <div style={{ paddingTop: 15 }}>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {Intl.NumberFormat("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(Number(genSumCoursePrice().price))}
              </label>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingLeft: 5,
                  fontSize: 18,
                }}
              >
                บาท
              </label>
            </div>
          </Col> */}

          {/* <Col span={12}>
            <div>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingRight: 10,
                  fontSize: 18,
                  color: "#FF7F00",
                  float: "right",
                }}
              >
                ราคาสุทธิ{" "}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#FF7F00",
                }}
              >
                {Intl.NumberFormat("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(Number(genSumCoursePrice().netPrice))}
              </label>
              <label
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingLeft: 5,
                  fontSize: 18,
                  color: "#FF7F00",
                }}
              >
                บาท
              </label>
            </div>
          </Col> */}
        </Row>
      </Card>
    );
  };

  const getPaymentAccount = async () => {
    const result = await getPaymentAccountFetch();
    // console.log("getPaymentAccount : ", result);
    setPaymentAccount(result);
  };

  const genPaymentMethod = () => {
    return (
      <Card
        bordered
        title={
          <label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>
            ช่องทางชำระเงิน
          </label>
        }
        style={{
          width: "100%",
          borderRadius: 10,
          boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.6)",
          height: "100%",
        }}
      >
        <center>
          <List style={{ width: "80%" }}>
            {/* โอนเงิน */}
            <Popover
              content={
                <div style={{ display: "grid" }}>
                  <div style={{ paddingBottom: 12 }}>
                    <label>{usage?.detail}</label>
                  </div>
                  <div style={{ paddingBottom: 12 }}>
                    <Video
                      url={usage.videoUrl}
                      title={""}
                      height={200}
                      // width={"100%"}
                    />
                  </div>
                </div>
              }
              title={usage?.title}
            >
              <List.Item
                className={"payment-method"}
                style={{
                  height: width <= 577 ? "auto" : 80,
                  borderRadius: 10,
                  borderLeft: "2px solid #FF7F00",
                  borderRight: "2px solid #FF7F00",
                  borderBottom: "2px solid #FF7F00",
                  borderTop: "2px solid #FF7F00",
                  boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                }}
                onClick={async () => {
                  const isLogin = await detectSignIn();
                  if (isLogin) {
                    // let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                    // console.log("getPaymentOrderDetectReBuyByUserId : ", result)
                    // if (result?.isPaid && !isExpire) {
                    //     Notification("warning", "เเจ้งเตือน!", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่');
                    // } else {
                    //     if (!creditCardLoading) {
                    //         setPaymentmethodLoading(true)

                    setPamentTransferModal({
                      isShow: true,
                      title: "โอนเงินผ่านบัญชีธนาคาร",
                    });

                    form.resetFields();
                    setImgTransferSlipURL({
                      loading: false,
                      imageUrl: null,
                    });

                    //         setPaymentmethodLoading(false)
                    //     } else {
                    //         Notification("warning", "เเจ้งเตือน!", 'ขณะนี้ อยู่ระหว่างการดำเนินการชำระเงิน')
                    //     }
                    // }
                  } else {
                    Notification(
                      "warning",
                      "เเจ้งเตือน!",
                      "กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !"
                    );
                  }
                }}
              >
                <Row
                  style={{ width: "100%", paddingLeft: 18, paddingRight: 18 }}
                >
                  <Col
                    span={width < 1400 ? 24 : 12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: width > 1400 ? "left" : "center",
                    }}
                  >
                    <label
                      style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      โอนเงิน
                    </label>
                  </Col>
                  <Col
                    span={width < 1400 ? 24 : 12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: width > 1400 ? "right" : "center",
                    }}
                  >
                    <Image
                      height={30}
                      width={"auto"}
                      style={{ borderRadius: 10 }}
                      preview={false}
                      src="error"
                      fallback={`${serverUrl}/${paymentAccount?.bank_category?.bankLogoURL}`}
                    />
                  </Col>
                </Row>
              </List.Item>
            </Popover>
          </List>
        </center>
      </Card>
    );
  };

  const [usage, setUsage] = useState(false);
  // const getUsageById = async () => {
  //   let objSearch = {
  //     type: "payment",
  //   };
  //   // const result = await getUsageByIdFetch(objSearch);
  //   // console.log("getUsageByIdFetch : ", result)
  //   setUsage(result);
  // };

  const getBaseApi = async () => {
    setLoading(true);

    const isLogin = await detectSignIn();
    if (isLogin) {
      let uid = firebase.auth().currentUser.uid;

      getDay();
      getMonth();
      getYear();
      setUid(uid);

      getPaymentAccount();

      await detectCourseHasBeenPurchased(uid);

      await getCourseById();
      // await getUsageById();
    } else {
      navigate("/login");
    }

    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <>
      <Header firebase={props.firebase} user={props.user} />

      <article>
        <Content style={{ backgroundColor: "#FFFFFF" }}>
          <article>
            {!loading ? (
              <div
                style={{ marginInline: "auto", maxWidth: 1200, marginTop: 37 }}
              >
                <Row
                  style={{
                    maxWidth: 724,
                  }}
                >
                  <Row style={{ width: "100%" }}>
                    <Col
                      span={24}
                      style={{ paddingLeft: 15, paddingRight: 15 }}
                    >
                      <Steps
                        current={currentStep}
                        labelPlacement="vertical"
                        items={[
                          {
                            title: "เลือกวิธีการชำระเงิน",
                          },
                          {
                            title: "ชำระเงิน เเละส่งหลักฐาน",
                          },
                          {
                            title: "รับหลักฐานชำระเงิน",
                          },
                        ]}
                      />
                      {/* <Button
                        onClick={() => setCurrentStep((prev) => (prev + 1) % 3)}
                      >
                        Next step
                      </Button> */}
                    </Col>
                  </Row>
                  {currentStep === 0 && (
                    <>
                      <Col
                        span={24}
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 37,
                          //   paddingBottom: 30,
                        }}
                      >
                        {genOrderedList()}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        {
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 24,
                              textAlign: "center",
                              marginTop: 37,
                            }}
                          >
                            ช่องทางชำระเงิน (คลิก
                            <img
                              src="/assets/images/page/payment/arrow-down.png"
                              width={42}
                              height={42}
                              alt="arrow-down"
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                              }}
                            />
                            )
                          </div>
                        }
                      </Col>
                      <Row
                        style={{
                          marginInline: "auto",
                          marginTop: 24,
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Col
                          span={24}
                          style={{ paddingLeft: 15, paddingRight: 15 }}
                        >
                          <Card
                            bordered
                            style={{
                              borderRadius: 10,
                              boxShadow: "0px 4px 8px 0px #00000040",
                              cursor: "pointer",
                              maxWidth: 491,
                              marginInline: "auto",
                            }}
                            onClick={() => {
                              setCurrentStep(1);
                            }}
                          >
                            <Row>
                              <Col
                                span={6}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "center",

                                  //   paddingBottom: 24,
                                }}
                              >
                                <img
                                  src="/assets/images/payment/kbank.png"
                                  width={48}
                                  height={48}
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",

                                    objectFit: "cover",
                                    marginRight: 12,
                                  }}
                                />
                              </Col>

                              <Col
                                span={18}
                                style={{
                                  paddingLeft: width < 1200 ? 0 : 24,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  className="payment-order-title"
                                  style={{
                                    fontWeight: 400,
                                  }}
                                >
                                  ชำระเงินด้วยโอนเงิน (กสิกรไทย)
                                </label>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}
                  {currentStep === 1 && (
                    <>
                      <Col
                        span={24}
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 32,
                            textAlign: "center",
                            fontWeight: 500,
                            marginTop: 24,
                          }}
                        >
                          จำนวนเงินที่ต้องชำระ&nbsp;
                          <span style={{ color: "red" }}>
                            {Intl.NumberFormat("en", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(resultBasketOrder[0]?.price)}
                          </span>
                          &nbsp;บาท
                        </p>
                      </Col>
                      {/**Payment form */}
                      <Col span={24} style={{ marginTop: 24 }}>
                        <Row gutter={[12, 12]}>
                          <Col span={6}>
                            <label style={{ float: "right" }}>ธนาคาร</label>
                          </Col>
                          <Col span={18}>
                            <label>
                              {paymentAccount?.bank_category?.bankName ??
                                "ธนาคารกสิกรไทย"}
                            </label>
                          </Col>

                          <Col span={6}>
                            <label style={{ float: "right" }}>ชื่อบัญชี</label>
                          </Col>
                          <Col span={18}>
                            <label>
                              {paymentAccount?.accountName ?? "DR.DUANG"}
                            </label>
                          </Col>

                          <Col span={6}>
                            <label style={{ float: "right" }}>
                              เลขที่บัญชี
                            </label>
                          </Col>
                          <Col span={18}>
                            <label>
                              {paymentAccount?.accountNumber ?? "732-2-26013-6"}
                            </label>
                          </Col>

                          <Col span={6}>
                            <label style={{ float: "right" }}>ส่งหลักฐาน</label>
                          </Col>
                          <Col span={18}>
                            <Row gutter={[12, 0]}>
                              <Col span={24}>
                                <label>
                                  วันเเละเวลาที่โอน (ดูในสลิปธนาคาร)
                                </label>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={5}>
                                <Form
                                  form={form}
                                  layout="vertical"
                                  onFinish={onFinish}
                                >
                                  <Form.Item
                                    name="day"
                                    label="วัน"
                                    style={{ width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "กรุณาเลือกวัน",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="วัน"
                                      optionFilterProp="children"
                                      allowClear
                                    >
                                      {day.map((val, index) => (
                                        <Option key={index} value={val.value}>
                                          {val.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Form>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={7}>
                                <Form
                                  form={form}
                                  layout="vertical"
                                  onFinish={onFinish}
                                >
                                  <Form.Item
                                    name="month"
                                    label="เดือน"
                                    style={{ width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "กรุณาเลือกเดือน",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="เดือน"
                                      optionFilterProp="children"
                                      allowClear
                                    >
                                      {month.map((val, index) => (
                                        <Option key={index} value={val.value}>
                                          {val.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Form>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Form
                                  form={form}
                                  layout="vertical"
                                  onFinish={onFinish}
                                >
                                  <Form.Item
                                    name="year"
                                    label="ปี"
                                    style={{ width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "กรุณาเลือกปี",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder="ปี"
                                      optionFilterProp="children"
                                      allowClear
                                    >
                                      {year.map((val, index) => (
                                        <Option key={index} value={val.value}>
                                          {val.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Form>
                              </Col>

                              <Col xs={24} sm={12} md={8} lg={6}>
                                <Form
                                  form={form}
                                  layout="vertical"
                                  onFinish={onFinish}
                                >
                                  <Form.Item
                                    name="time"
                                    label="เวลา"
                                    style={{ width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "กรุณาเลือกเวลา",
                                      },
                                    ]}
                                  >
                                    <TimePicker
                                      showNow={false}
                                      placeholder="เวลา"
                                      style={{ width: "100%" }}
                                      locale={{
                                        ...locale,
                                        lang: {
                                          ...locale.lang,
                                          // now: "เวลาปัจจุบัน",
                                          ok: "ตกลง",
                                        },
                                      }}
                                      format={"HH:mm"}
                                    />
                                  </Form.Item>
                                </Form>
                              </Col>

                              <Col span={24} style={{ paddingBottom: 6 }}>
                                <Form
                                  form={form}
                                  layout="vertical"
                                  onFinish={onFinish}
                                >
                                  <Form.Item
                                    name="imgTransferSlipURL"
                                    label={
                                      <>
                                        <label
                                          style={{
                                            color: "red",
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          *
                                        </label>
                                        <label
                                          style={{
                                            margin: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 5,
                                          }}
                                        >
                                          หลักฐานการโอน (สลิป)
                                        </label>
                                      </>
                                    }
                                  >
                                    <div style={{ paddingBottom: 12 }}>
                                      {imgTransferSlipURL.imageUrl ? (
                                        <Image
                                          style={{
                                            width: 250,
                                            border: "1px solid #e8e8e8",
                                            borderRadius: 8,
                                          }}
                                          alt="avatar"
                                          src={`${serverUrl}/${imgTransferSlipURL.imageUrl}`}
                                          // preview={{
                                          //   src: `${serverUrl}/${imgTransferSlipURL.imageUrl}`,
                                          // }}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            width: 330,
                                            height: 180,
                                            backgroundColor: "#D9D9D9",
                                            borderRadius: 8,
                                            display: "grid",
                                            placeItems: "center",
                                          }}
                                        >
                                          <Image
                                            width={142}
                                            height={157}
                                            style={{
                                              maxWidth: "100%",
                                              objectFit: "cover",
                                              border: "1px solid #e8e8e8",
                                            }}
                                            src="error"
                                            fallback={`./assets/images/payment/slip-df.png`}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    <Upload
                                      {...optionTransferSlipImage}
                                      listType="picture"
                                      accept="image/jpeg, image/png, image/jfif"
                                      maxCount={1}
                                      showUploadList={false}
                                    >
                                      <Button
                                        type="primary"
                                        disabled={props.isDisabled}
                                        style={{ width: 245 }}
                                        icon={
                                          imgTransferSlipURL.loading ? (
                                            <LoadingOutlined />
                                          ) : (
                                            <UploadOutlined />
                                          )
                                        }
                                      >
                                        อัพโหลด
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                </Form>
                              </Col>
                            </Row>
                          </Col>

                          {width >= 590 ? (
                            <Col xs={24} sm={24} md={6} lg={6}></Col>
                          ) : (
                            []
                          )}
                          <Col xs={24} sm={24} md={18} lg={18}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon
                                icon="carbon:information"
                                style={{
                                  color: "#F3BB02",
                                  width: 20,
                                  height: 20,
                                }}
                              />
                              <label style={{ paddingLeft: 5 }}>
                                กรุณาเก็บหลักฐานการโอนเงินไว้
                              </label>
                            </div>
                          </Col>

                          {width >= 590 ? (
                            <Col xs={24} sm={24} md={6} lg={6}></Col>
                          ) : (
                            []
                          )}
                          <Col xs={24} sm={24} md={18} lg={18}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon
                                icon="carbon:information"
                                style={{
                                  color: "#F3BB02",
                                  width: 20,
                                  height: 20,
                                }}
                              />
                              <label style={{ paddingLeft: 5 }}>
                                การตรวจสอบจะใช้เวลาไม่เกิน 24 ชม.
                                เราจะดำเนินการให้เร็วที่สุด
                              </label>
                            </div>
                          </Col>
                          {width >= 590 ? (
                            <Col xs={24} sm={24} md={6} lg={6}></Col>
                          ) : (
                            []
                          )}
                          <Col xs={24} sm={24} md={18} lg={18} style={{ paddingBottom: 48 }}>
                            <Button
                              type="primary"
                              loading={paymentmethodLoading}
                              onClick={() => {
                                form.submit();
                              }}
                            >
                              {!paymentmethodLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Icon
                                    icon="bi:send"
                                    style={{ color: "white" }}
                                  />
                                  <label
                                    style={{
                                      paddingLeft: 10,
                                      cursor: "pointer",
                                    }}
                                  >
                                    ส่งหลักฐานการชำระเงิน
                                  </label>
                                </div>
                              ) : (
                                []
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <Col
                        span={24}
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          textAlign: "center",
                          marginTop: 37,
                        }}
                      >
                        <img
                          src="/assets/images/page/payment/success.png"
                          height={199}
                          width={199}
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                        <div
                          style={{
                            fontSize: 32,
                            fontWeight: 500,
                            marginTop: 24,
                          }}
                        >
                          เราได้รับหลักฐานการชำระเงินเเล้ว
                        </div>
                        <div style={{ fontSize: 26 }}>
                          การตรวจสอบจะใช้เวลาไม่เกิน 24 ชม.
                          เราจะดำเนินการให้เร็วที่สุด
                        </div>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => {
                            navigate("/");
                          }}
                          style={{ marginTop: 24, fontSize: 16 }}
                        >
                          กลับไปหน้า
                        </Button>
                      </Col>
                    </>
                  )}

                  {/* <Col
                    xs={24}
                    lg={24}
                    xl={12}
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingBottom: 30,
                    }}
                  >
                    {genPaymentMethod()}
                  </Col> */}
                </Row>

                <Modal
                  maskClosable={false}
                  title={
                    <label style={{ fontWeight: 100 }}>
                      {pamentTransferModal.title}
                    </label>
                  }
                  visible={pamentTransferModal.isShow}
                  width={550}
                  loading={paymentmethodLoading}
                  onCancel={() => {
                    // close modal
                    setFormDefault();
                  }}
                  footer={[
                    <Button
                      type="primary"
                      loading={paymentmethodLoading}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      {!paymentmethodLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Icon icon="bi:send" style={{ color: "white" }} />
                          <label style={{ paddingLeft: 10, cursor: "pointer" }}>
                            ส่งหลักฐานการชำระเงิน
                          </label>
                        </div>
                      ) : (
                        []
                      )}
                    </Button>,
                  ]}
                >
                  <Row gutter={[12, 12]}>
                    <Col span={6}>
                      <label style={{ float: "right" }}>ธนาคาร</label>
                    </Col>
                    <Col span={18}>
                      <label>
                        {paymentAccount?.bank_category?.bankName ?? "-"}
                      </label>
                    </Col>

                    <Col span={6}>
                      <label style={{ float: "right" }}>ชื่อบัญชี</label>
                    </Col>
                    <Col span={18}>
                      <label>{paymentAccount?.accountName ?? "-"}</label>
                    </Col>

                    <Col span={6}>
                      <label style={{ float: "right" }}>เลขที่บัญชี</label>
                    </Col>
                    <Col span={18}>
                      <label>{paymentAccount?.accountNumber ?? "-"}</label>
                    </Col>

                    <Col span={6}>
                      <label style={{ float: "right" }}>ส่งหลักฐาน</label>
                    </Col>
                    <Col span={18}>
                      <Row gutter={[12, 0]}>
                        <Col span={24}>
                          <label>วันเเละเวลาที่โอน (ดูในสลิปธนาคาร)</label>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={5}>
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="day"
                              label="วัน"
                              style={{ width: "100%" }}
                              rules={[
                                { required: true, message: "กรุณาเลือกวัน" },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="วัน"
                                optionFilterProp="children"
                                allowClear
                              >
                                {day.map((val, index) => (
                                  <Option key={index} value={val.value}>
                                    {val.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Form>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={7}>
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="month"
                              label="เดือน"
                              style={{ width: "100%" }}
                              rules={[
                                { required: true, message: "กรุณาเลือกเดือน" },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="เดือน"
                                optionFilterProp="children"
                                allowClear
                              >
                                {month.map((val, index) => (
                                  <Option key={index} value={val.value}>
                                    {val.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Form>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="year"
                              label="ปี"
                              style={{ width: "100%" }}
                              rules={[
                                { required: true, message: "กรุณาเลือกปี" },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="ปี"
                                optionFilterProp="children"
                                allowClear
                              >
                                {year.map((val, index) => (
                                  <Option key={index} value={val.value}>
                                    {val.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Form>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="time"
                              label="เวลา"
                              style={{ width: "100%" }}
                              rules={[
                                { required: true, message: "กรุณาเลือกเวลา" },
                              ]}
                            >
                              <TimePicker
                                showNow={false}
                                placeholder="เวลา"
                                style={{ width: "100%" }}
                                locale={{
                                  ...locale,
                                  lang: {
                                    ...locale.lang,
                                    // now: "เวลาปัจจุบัน",
                                    ok: "ตกลง",
                                  },
                                }}
                                format={"HH:mm"}
                              />
                            </Form.Item>
                          </Form>
                        </Col>

                        <Col span={24} style={{ paddingBottom: 6 }}>
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="imgTransferSlipURL"
                              label={
                                <>
                                  <label
                                    style={{
                                      color: "red",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    *
                                  </label>
                                  <label
                                    style={{
                                      margin: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 5,
                                    }}
                                  >
                                    หลักฐานการโอน (สลิป)
                                  </label>
                                </>
                              }
                            >
                              <div style={{ paddingBottom: 12 }}>
                                {imgTransferSlipURL.imageUrl ? (
                                  <Image
                                    style={{
                                      width: 250,
                                      border: "1px solid #e8e8e8",
                                      borderRadius: 8,
                                    }}
                                    alt="avatar"
                                    src={`${serverUrl}/${imgTransferSlipURL.imageUrl}`}
                                    preview={{
                                      src: `${serverUrl}/${imgTransferSlipURL.imageUrl}`,
                                    }}
                                  />
                                ) : (
                                  <Image
                                    style={{
                                      width: 250,
                                      border: "1px solid #e8e8e8",
                                    }}
                                    src="error"
                                    fallback={`${imgDefaultUrl}`}
                                  />
                                )}
                              </div>

                              <Upload
                                {...optionTransferSlipImage}
                                listType="picture"
                                accept="image/jpeg, image/png, image/jfif"
                                maxCount={1}
                                showUploadList={false}
                              >
                                <Button
                                  type="primary"
                                  disabled={props.isDisabled}
                                  style={{ width: 245 }}
                                  icon={
                                    imgTransferSlipURL.loading ? (
                                      <LoadingOutlined />
                                    ) : (
                                      <UploadOutlined />
                                    )
                                  }
                                >
                                  อัพโหลด
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Form>
                        </Col>
                      </Row>
                    </Col>

                    {width >= 590 ? (
                      <Col xs={24} sm={24} md={4} lg={4}></Col>
                    ) : (
                      []
                    )}
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon
                          icon="carbon:information"
                          style={{ color: "#FF7F00", width: 20, height: 20 }}
                        />
                        <label style={{ paddingLeft: 5 }}>
                          กรุณาเก็บหลักฐานการโอนเงินไว้
                        </label>
                      </div>
                    </Col>

                    {width >= 590 ? (
                      <Col xs={24} sm={24} md={4} lg={4}></Col>
                    ) : (
                      []
                    )}
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon
                          icon="carbon:information"
                          style={{ color: "#FF7F00", width: 20, height: 20 }}
                        />
                        <label style={{ paddingLeft: 5 }}>
                          การตรวจสอบจะใช้เวลาไม่เกิน 24 ชม.
                          เราจะดำเนินการให้เร็วที่สุด
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Modal>
              </div>
            ) : (
              <LoadingPage loading={loading} />
            )}
          </article>
        </Content>
      </article>

      <Footer />
    </>
  );
}
