/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Space, List } from "antd";
import moment from "moment";
import { useDimensions } from "../../../common/mamager/ScreenManager";
import { serverUrl } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setBasketOrderStateToSuccess } from "../../../actions/basketOrder.action";
import { getCourseByIdFetch } from "../../classroomStudy/API";
import { useEffect, useRef, useState } from "react";
import firebase from "../../../config/firebase-config";
import {
  detectCourseAlreadyPurchased,
  detectCourseExpired,
} from "../../../common/mamager/DetectManager";
const dateTimeFormat = "DD/MM/YYYY HH:mm";

export default function ClassroomItem(props) {
  const navigate = useNavigate();
  const data = [""];
  const { width } = useDimensions();

  const dispatch = useDispatch();

  const { resultBasketOrder } = useSelector(
    ({ basketOrderReducer }) => basketOrderReducer
  );
  const [courseStatus, setCourseStatus] = useState("NOT_PURCHASED");

  const isCourseExpired = (expireDate) => {
    let currentDate = new Date();
    let expirationDate = new Date(expireDate);

    if (currentDate > expirationDate) {
      return true;
    } else {
      return false;
    }
  };
  const getBaseApi = async () => {
    const responseDetectCoursePurchased = await detectCourseAlreadyPurchased(
      firebase.auth().currentUser?.uid,
      props.item.course.courseId
    );

    const responseDetectCourseExpired = await detectCourseExpired(
      firebase.auth().currentUser?.uid,
      props.item.course.courseId
    );

    if (responseDetectCourseExpired === "EXPIRED") {
      setCourseStatus("EXPIRED");
    } else if (responseDetectCoursePurchased === "AWAITING_PAYMENT_APPROVAL") {
      setCourseStatus("AWAITING_PAYMENT_APPROVAL");
    } else if (responseDetectCoursePurchased === "ALREADY_PURCHASED") {
      setCourseStatus("ALREADY_PURCHASED");
    } else {
      setCourseStatus("NOT_PURCHASED");
    }
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Col
      span={24}
      style={{
        boxShadow: "0px 4px 8px #00000040",
        borderRadius: 6,
        marginBottom: 24,
      }}
    >
      <div
        style={{
          backgroundColor: isCourseExpired(props?.item?.expireDate)
            ? "#DDDDDD"
            : "white",
          paddingLeft: 12,
          paddingRight: 12,
          paddingBlock: 12,
          borderRadius: 6,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 12,
          alignItems: "center",
        }}
      >
        {/* Left Section: Course Image */}
        <div style={{}}>
          <img
            src={`${serverUrl}/${props?.item?.course?.imgCoverUrl}`}
            alt="Course"
            style={{
              width: width < 440 ? "100%" : 142,
              objectFit: width < 440 ? "cover" : "contain",
              height: width < 440 ? "auto" : 102,
              borderRadius: "8px",
              filter: isCourseExpired(props?.item?.expireDate)
                ? "grayscale(100%)"
                : "",
            }}
          />
        </div>

        {/* Center Section: Course Info */}
        <div style={{ flex: "1 1 auto", paddingLeft: 10 }}>
          <div style={{ marginBottom: 8 }}>
            <label style={{ color: "black", fontWeight: 600 }}>
              {props?.item?.course?.title ?? "-"}
            </label>
          </div>
          <div style={{ marginBottom: 8 }}>
            <label style={{ color: "black" }}>
              วันที่ซื้อคอร์ส{" "}
              {moment(props?.item?.createdAt).format(dateTimeFormat)} (
              {Number(moment(props?.item?.createdAt).format("YYYY")) + 543})
            </label>
          </div>
          <div>
            <label style={{ color: "black" }}>
              วันที่หมดอายุ{" "}
              {moment(props?.item?.expireDate).format(dateTimeFormat)} (
              {Number(moment(props?.item?.expireDate).format("YYYY")) + 543})
            </label>
          </div>
        </div>

        {/* Right Section: Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            flex: "0 0 auto",
            alignSelf: "flex-start",
          }}
        >
          <Button
            type="primary"
            disabled={
              isCourseExpired(props?.item?.expireDate) ||
              courseStatus === "AWAITING_PAYMENT_APPROVAL"
            }
            onClick={() => {
              if (!isCourseExpired(props?.item?.expireDate)) {
                navigate(
                  "/classroom-course-study?course-id=" +
                    props.item.course.courseId
                );
              }
            }}
            style={{ width: 100 }}
          >
            <label style={{ cursor: "pointer" }}>เข้าเรียน</label>
          </Button>
        </div>
        {isCourseExpired(props?.item?.expireDate) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <label>หมดอายุเเล้ว! โปรดต่ออายุ เพื่อเข้าเรียนอีกครั้ง</label>
            <Button
              type="primary"
              onClick={async () => {
                if (isCourseExpired(props?.item?.expireDate)) {
                  let findCourseId = resultBasketOrder?.find(
                    (fnd) => fnd.courseId === props?.item?.courseId
                  );
                  if (!findCourseId) {
                    let result = await getCourseByIdFetch(
                      props?.item?.courseId
                    );
                    let tmpArr = [...resultBasketOrder, ...[result]];
                    dispatch(setBasketOrderStateToSuccess(tmpArr));
                    navigate("/payment");
                  }
                }
              }}
              style={{ width: 100 }}
            >
              <label style={{ cursor: "pointer" }}>ต่ออายุ</label>
            </Button>
          </div>
        )}
        {courseStatus === "AWAITING_PAYMENT_APPROVAL" && (
          <>
            <p style={{ fontSize: 24 }}>
              <strong>คุณได้ซื้อคอร์สนี้เเล้ว!</strong>
            </p>
            <p style={{ fontSize: 24 }}>ขณะนี้ อยู่ระหว่างตรวจสอบการชำระเงิน</p>
          </>
        )}
      </div>
    </Col>
  );
}
