/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import "../home/css/index.css";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import LoadingPage from "../../common/components/loading/page";
import { getPageHomeFetch } from "./API";
import Intro from "./components/Intro";
import OurService from "./components/OurService";
import ShotOfIdeas from "./components/ShotOfIdeas";
import YoutubeContact from "./components/YoutubeContact";
import ChooseWe from "./components/ChooseWe";
import UserReview from "./components/UserReview";
import { Element } from "react-scroll";
export default function Home({ firebase, user }) {
  const [loading, setLoading] = useState(false);

  const [intro, setIntro] = useState();

  const getPageHome = async () => {
    let result = await getPageHomeFetch();
    setIntro(result);
  };

  const getRequestBaseApi = async () => {
    setLoading(true);

    await getPageHome();

    setLoading(false);
  };

  useEffect(() => {
    getRequestBaseApi();
  }, []);

  return (
    <>
      <Header firebase={firebase} user={user} />

      <div>
        {!loading ? (
          <>
            {/**Intro Component*/}
            <Intro intro={intro} />
            {/**Our services Component*/}
            <OurService />

            {/**Knowledge source Component */}
            <Element name="shot-of-ideas">
              <ShotOfIdeas />
            </Element>
            {/**Youtube Contact Componnet */}
            <YoutubeContact />

            {/**Choose We Component */}
            <ChooseWe />

            {/**User Review Component */}
            <UserReview />
          </>
        ) : (
          <article style={{ backgroundColor: "#262626" }}>
            <LoadingPage loading={loading} />
          </article>
        )}
      </div>
      <Footer />
    </>
  );
}
