/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDimensions } from "../../mamager/ScreenManager";
import { detectSignIn } from "../../mamager/DetectManager";
import { getUserByUidFetch } from "./API";
import firebase from "./../../../config/firebase-config";
import LoadingPage from "../loading/page";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import {
  Drawer,
  Button,
  Avatar,
  // Divider
} from "antd";
import "../header/css/index.css";

// import { useDispatch, useSelector } from "react-redux"
// import { setBasketOrderStateToSuccess } from "../../../actions/basketOrder.action"
// import { serverUrl } from "../../../constants"

export default function Header(props) {
  const navigate = useNavigate();
  // const dispatch = useDispatch()

  const [userInformation, setUserInformation] = useState();

  const [isSignIn, setIsSignIn] = useState(false);

  const { width } = useDimensions();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({ current: "mail", visible: false });

  // const [open, setOpen] = useState(false)

  const { pathname } = useLocation();

  // const { resultBasketOrder } = useSelector(
  //   ({ basketOrderReducer }) => basketOrderReducer
  // )

  // const handleOpenChange = (newOpen) => {
  //   setOpen(newOpen)
  // }

  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };

  const openPath = (menu) => {
    if (pathname !== "/") {
      navigate("/");
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      scroller.scrollTo(menu, {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -100,
      });
    }, 1000);
  };

  const genProfileDisplay = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          if (isSignIn) {
            navigate("/account?tab=profile");
          } else {
            navigate("/login");
          }
        }}
      >
        {width >= 1100 ? (
          <div
            style={{
              display: "block",
              float: "right",
              paddingRight: 18,
              color: "white",
              fontSize: 20,
            }}
          >
            {userInformation?.username ? userInformation.username : ""}
          </div>
        ) : (
          []
        )}

        <Avatar
          style={{
            float: "right",
            marginRight: 20,
            maxWidth: "100%",
          }}
          className="mt-1"
          size={74}
          src={
            userInformation?.imageProfile
              ? userInformation.imageProfile
              : `/assets/images/profile/personDefault.jpg`
          }
        />
      </div>
    );
  };

  // const genBasketList = () => {
  //   return (
  //     <div style={{ width: 370, display: "grid", alignItems: "center" }}>
  //       {resultBasketOrder?.map((item) => {
  //         return (
  //           <>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 alignItems: "flex-start",
  //                 width: "100%",
  //               }}
  //             >
  //               <img
  //                 src={`${serverUrl}/${item?.imgCoverUrl}`}
  //                 style={{
  //                   height: 60,
  //                   paddingTop: 5,
  //                   paddingBottom: 5,
  //                   cursor: "pointer",
  //                   borderRadius: 8,
  //                 }}
  //               />
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   paddingLeft: 10,
  //                   width: "100%",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     display: "grid",
  //                     alignItems: "center",
  //                     width: "100%",
  //                     paddingRight: 10,
  //                   }}
  //                 >
  //                   <label
  //                     className="basket-order-title"
  //                     style={{ fontWeight: "bold" }}
  //                   >
  //                     {item?.title}
  //                   </label>
  //                   <label className="basket-order-subtitle">
  //                     {item?.subTitle}
  //                   </label>
  //                   <label style={{ fontWeight: 600 }}>
  //                     ราคา{" "}
  //                     {Intl.NumberFormat("en", {
  //                       maximumFractionDigits: 2,
  //                       minimumFractionDigits: 2,
  //                     }).format(item?.price)}{" "}
  //                     บาท
  //                   </label>
  //                 </div>

  //                 <Button
  //                   type="primary"
  //                   size="small"
  //                   onClick={() => {
  //                     let tmpArr = resultBasketOrder.filter(
  //                       (fill) => fill.courseId !== item.courseId
  //                     );
  //                     dispatch(setBasketOrderStateToSuccess(tmpArr));
  //                   }}
  //                 >
  //                   <div
  //                     style={{
  //                       display: "flex",
  //                       alignItems: "center",
  //                       justifyContent: "center",
  //                       cursor: "pointer",
  //                     }}
  //                   >
  //                     <Icon
  //                       icon="ph:trash-bold"
  //                       style={{
  //                         color: "white",
  //                         height: 16,
  //                         width: 16,
  //                         cursor: "pointer",
  //                       }}
  //                     />
  //                   </div>
  //                 </Button>
  //               </div>
  //             </div>
  //             <Divider style={{ marginTop: 11, marginBottom: 11 }} />
  //           </>
  //         );
  //       })}

  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <div style={{ display: "grid", alignItems: "center" }}>
  //           <Button
  //             type="primary"
  //             disabled={resultBasketOrder.length > 0 ? false : true}
  //             onClick={() => {
  //               navigate("/payment");
  //             }}
  //           >
  //             ชำระเงิน
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const genMenuTop = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
        <div
          onClick={() => {
            setTimeout(function () {
              navigate("/course");
              window.scrollTo(0, 0);
            }, 1000);
          }}
          className="divStyle"
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: 20,
            }}
          >
            <label style={{ cursor: "pointer", fontSize: 20 }}>
              คอร์สเรียน
            </label>
          </div>
        </div>

        <div
          onClick={() => {
            setTimeout(function () {
              navigate("/prediction");
              window.scrollTo(0, 0);
            }, 1000);
          }}
          className="divStyle"
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: 20,
            }}
          >
            <label style={{ cursor: "pointer", fontSize: 20 }}>โหรทำนาย</label>
          </div>
        </div>

        <div
          onClick={() => {
            setTimeout(function () {
              navigate("/ebook");
              window.scrollTo(0, 0);
            }, 1000);
          }}
          className="divStyle"
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: 20,
            }}
          >
            <label style={{ cursor: "pointer", fontSize: 20 }}>E-Book</label>
          </div>
        </div>

        <div
          onClick={() => {
            setTimeout(function () {
              openPath("shot-of-ideas");
            }, 1000);
          }}
          className="divStyle"
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: 20,
            }}
          >
            <label style={{ cursor: "pointer", fontSize: 20 }}>
              เเหล่งความรู้
            </label>
          </div>
        </div>

        <div
          onClick={() => {
            setTimeout(function () {
              navigate("/contact");
              window.scrollTo(0, 0);
            }, 1000);
          }}
          className="divStyle"
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
            }}
          >
            <label style={{ cursor: "pointer", fontSize: 20 }}>ติดต่อเรา</label>
          </div>
        </div>
      </div>
    );
  };

  const genMenuRight = () => {
    return (
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "#F3BB02",
          height: "auto",
        }}
      >
        <div style={{ display: "grid", alignItems: "center", width: 378 }}>
          <div
            onClick={() => {
              setTimeout(function () {
                navigate("/");
                window.scrollTo(0, 0);
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                หน้าหลัก
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              setTimeout(function () {
                navigate("/course");
                window.scrollTo(0, 0);
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                คอร์สเรียน
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              setTimeout(function () {
                navigate("/prediction");
                window.scrollTo(0, 0);
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                โหรทำนาย
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              setTimeout(function () {
                navigate("/ebook");
                window.scrollTo(0, 0);
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                E-Book
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              setTimeout(function () {
                openPath("shot-of-ideas");
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                เเหล่งความรู้
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              setTimeout(function () {
                navigate("/contact");
                window.scrollTo(0, 0);
              }, 1000);
            }}
            className="sidebar-item"
          >
            <div style={{ color: "white", cursor: "pointer" }}>
              <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                ติดต่อเรา
              </label>
            </div>
          </div>
          {!firebase.auth().currentUser?.uid && (
            <div
              onClick={() => {
                setTimeout(function () {
                  navigate("/login");
                  window.scrollTo(0, 0);
                }, 1000);
              }}
              className="sidebar-item"
            >
              <div style={{ color: "white", cursor: "pointer" }}>
                <label style={{ padding: 8, cursor: "pointer", fontSize: 18 }}>
                  เข้าสู่ระบบ
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getUserInformation = async () => {
    if (firebase.auth().currentUser?.uid) {
      const result = await getUserByUidFetch(
        firebase?.auth()?.currentUser?.uid
      );
      setUserInformation(result);
    } else {
      setUserInformation(null);
    }
  };

  const getBaseApi = async () => {
    setLoading(true);

    const isLogin = await detectSignIn();
    setIsSignIn(isLogin);

    await getUserInformation();

    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <>
      <header style={{ height: 96 }}>
        <div
          style={{
            position: "fixed",
            zIndex: 2,
            width: "100%",
            height: 96,
            backgroundColor: "#000000",
          }}
        >
          {!loading ? (
            <div
              style={{
                width: width < 1100 ? "100%" : "min(90%,75rem)",
                marginInline: "auto",
                height: 96,
                paddingLeft: width < 1100 ? "5%" : 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 96,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src={`/assets/images/logo/Du-SolarChart.png`}
                    style={{
                      height: 74,
                      width: width < 1100 ? 80 : 74,

                      paddingLeft: width > 925 ? 0 : 5,
                      cursor: "pointer",
                    }}
                  />

                  {width >= 1100 ? (
                    <span
                      style={{
                        color: "#FEE285",
                        fontSize: width < 500 ? 16 : 24,
                        fontWeight: "bolder",
                        marginLeft: 20,
                        cursor: "pointer",
                      }}
                    >
                      อ่านกรรมจากผังดาว
                    </span>
                  ) : (
                    []
                  )}
                </div>
                {width >= 1100 && genMenuTop()}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {!isSignIn && width >= 1100 ? (
                    <Button
                      size="medium"
                      onClick={() => {
                        setTimeout(function () {
                          navigate("/login");
                          window.scrollTo(0, 0);
                        }, 1000);
                      }}
                    >
                      เข้าสู่ระบบ
                    </Button>
                  ) : (
                    []
                  )}
                  {genProfileDisplay()}

                  <Drawer
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <label style={{ fontWeight: 400 }}>Witte Bangkok</label>
                      </div>
                    }
                    placement="right"
                    maskClosable={true}
                    onClose={onClose}
                    open={state?.visible}
                    bodyStyle={{ padding: "0px", backgroundColor: "black" }}
                    size="default"
                  >
                    {genMenuRight()}
                  </Drawer>

                  {width < 1100 ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        showDrawer();
                      }}
                      style={{
                        height: "100%",
                        width: 90,
                        borderRadius: 0,
                        alignSelf: "stretch",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="charm:menu-hamburger"
                          style={{
                            fontSize: "2.9em",
                            color: "white",
                          }}
                        />
                      </div>
                    </Button>
                  ) : (
                    []
                  )}
                </div>
              </div>
            </div>
          ) : (
            <LoadingPage loading={loading} />
          )}
        </div>
      </header>
    </>
  );
}
