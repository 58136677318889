import React, { useEffect, useState } from "react";
import "./css/index.css";
import { serverUrl } from "../../../../constants";
import parse from "html-react-parser";
import axios from "axios";
const Intro = ({ intro }) => {
  return (
    <div className="intro">
      <div className="container">
        <div className="intro-1">
          <img
            src={`${serverUrl}/${intro?.imageUrl}`}
            alt="intro_logo"
            width={199}
            height={199}
            style={{ maxWidth: "100%", height: "auto", objectFit: "cover" }}
          />
          <div className="text">
            <h1
              style={{
                color: "#FEE285",
                fontSize: "clamp(24px, 24px + 2vw, 48px)",
              }}
            >
              {intro?.title}
            </h1>
            <p style={{ fontSize: "clamp(18px, 18px + 2vw, 40px)" }}>
              {intro?.subTitle}
            </p>
          </div>
        </div>
        <p style={{ fontSize: "clamp(14px, 14px + 2vw, 28px)", marginTop: 37 }}>
          {intro?.detail && parse(intro?.detail)}
        </p>
      </div>
    </div>
  );
};

export default Intro;
