/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Space,
  List,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  TimePicker,
  Card,
} from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import moment from "moment";
import { useDimensions } from "../../common/mamager/ScreenManager";
import { serverUrl } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { getPredictionByIdFetch } from "../predictionOpen/API";
import { auth } from "../../config/firebase-config";
import firebase from "../../config/firebase-config";
import {
  getCountryFetch,
  getPrefixAllFetch,
  getProvincesFetch,
  insertUserFetch,
} from "../register/API";
import { insertAccountVerifyIdentifyFetch } from "../account/API";
import {
  getDetectFilledInPersonalInfoFetch,
  updateUserInfoByIdFetch,
} from "./API";
import {
  detectPredictionAlreadyPurchased,
  detectPredictionExpired,
} from "../../common/mamager/DetectManager";
import LoadingPage from "../../common/components/loading/page";

const { Option } = Select;

const dateTimeFormat = "DD/MM/YYYY HH:mm";

export default function PredictionRoomItem(props) {
  const navigate = useNavigate();
  const data = [""];
  const { width } = useDimensions();
  const [modalActive, setModalActive] = useState(false);

  const dispatch = useDispatch();

  const { resultBasketOrder } = useSelector(
    ({ basketOrderReducer }) => basketOrderReducer
  );

  const isPredictionExpired = (expireDate) => {
    let currentDate = new Date();
    let expirationDate = new Date(expireDate);

    if (currentDate > expirationDate) {
      return true;
    } else {
      return false;
    }
  };

  //Modal
  const [form] = Form.useForm();
  const [day, setDay] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);

  const [provincesData, setProvincesData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [prefixData, setPrefixData] = useState([]);

  const [loadingSend, setLoadingSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDayNull, setIsDayNull] = useState(false);
  const [isMonthNull, setIsMonthNull] = useState(false);
  const [isTimeNull, setIsTimeNull] = useState(false);
  const [hasCompletePersonalInfo, setHasCompletePersonalInfo] = useState(false);
  const [predictionStatus, setPredictionStatus] = useState("NOT_PURCHASED");
  const getDay = () => {
    let tmpDay = [];
    for (let i = 1; i <= 31; i++) {
      tmpDay.push({
        label: i,
        value: i,
      });
    }
    setDay(tmpDay);
  };

  const getMonth = () => {
    let tmpMonth = [
      { label: "มกราคม", value: 1 },
      { label: "กุมภาพันธ์", value: 2 },
      { label: "มีนาคม", value: 3 },
      { label: "เมษายน", value: 4 },
      { label: "พฤษภาคม", value: 5 },
      { label: "มิถุนายน", value: 6 },
      { label: "กรกฎาคม", value: 7 },
      { label: "สิงหาคม", value: 8 },
      { label: "กันยายน", value: 9 },
      { label: "ตุลาคม", value: 10 },
      { label: "พฤศจิกายน", value: 11 },
      { label: "ธันวาคม", value: 12 },
    ];
    setMonth(tmpMonth);
  };

  const getYear = () => {
    let tmpYear = [];
    let a = moment().format("YYYY");
    let yearCurrent = Number(a) + 543 - 1;
    for (let i = yearCurrent; i > yearCurrent - 100; i--) {
      tmpYear.push({
        label: i + 1,
        value: i + 1,
      });
    }
    setYear(tmpYear);
  };
  const onFinish = async (values) => {
    // console.log("values : ", values)

    // console.log("isInfoRady : ", isInfoRady)

    setLoadingSend(true);

    let uid = firebase.auth().currentUser?.uid;

    let firstname = values.firstname ? values.firstname : null;
    let lastname = values.lastname ? values.lastname : null;
    let prefixId = values.prefix ? values.prefix : null;
    // let countryId = values.country ? values.country : null;
    // let provinceId = values.province ? values.province : null;
    let timeOfBirth = values.time
      ? moment(String(values.time)).format("HH:mm")
      : null;
    let dateOfBirth = values.day ? values.day : null;
    let monthOfBirth = values.month ? values.month : null;
    let yearOfBirth = values.year ? values.year : null;

    let obj = {
      uid,
      firstname,
      lastname,
      prefixId,
      // countryId,
      // provinceId,
      timeOfBirth,
      dateOfBirth,
      monthOfBirth,
      yearOfBirth,
    };

    const response = await updateUserInfoByIdFetch(obj);
    if (response.isSuccess) {
      //redirect to  prediction room
      navigate(
        "/prediction-open?prediction-id=" + props.item.prediction.predictionId
      );
    }
    // console.log("obj : ", obj)
  };

  const getProvinces = async () => {
    let result = await getProvincesFetch();
    setProvincesData(result);
  };

  const getPrefix = async () => {
    let result = await getPrefixAllFetch();
    setPrefixData(result);
  };

  const getCountry = async () => {
    let result = await getCountryFetch();
    setCountryData(result);
  };

  const getDetectFilledInPersonalInfo = async () => {
    const response = await getDetectFilledInPersonalInfoFetch(
      firebase.auth().currentUser?.uid
    );
    const isFilledInPersonalInfo = await response[0].isFilledInPersonalInfo;
    setHasCompletePersonalInfo(isFilledInPersonalInfo);
    return isFilledInPersonalInfo;
  };

  const getBaseApi = async () => {
    setLoading(true);

    getDay();
    getMonth();
    getYear();
    const responseDetectPredictionPurchased =
      await detectPredictionAlreadyPurchased(
        firebase.auth().currentUser?.uid,
        props.item.prediction.predictionId
      );

    const responseDetectPredictionExpired = await detectPredictionExpired(
      firebase.auth().currentUser?.uid,
      props.item.prediction.predictionId
    );

    if (responseDetectPredictionExpired === "EXPIRED") {
      setPredictionStatus("EXPIRED");
    } else if (
      responseDetectPredictionPurchased === "AWAITING_PAYMENT_APPROVAL"
    ) {
      setPredictionStatus("AWAITING_PAYMENT_APPROVAL");
    } else if (responseDetectPredictionPurchased === "ALREADY_PURCHASED") {
      setPredictionStatus("ALREADY_PURCHASED");
    } else {
      setPredictionStatus("NOT_PURCHASED");
    }

    const _isFilledInPersonalInfo = await getDetectFilledInPersonalInfo();
    if (!_isFilledInPersonalInfo) {
      await getProvinces();
      await getPrefix();
      await getCountry();
    }
    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Col
      span={24}
      style={{
        boxShadow: "0px 4px 8px #00000040",
        borderRadius: 6,
        marginBottom: 24,
      }}
    >
      <div
        style={{
          backgroundColor: isPredictionExpired(props?.item?.expireDate)
            ? "#DDDDDD"
            : "white",
          padding: "12px",
          borderRadius: 6,
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        {/* Content and Button Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: 12,
            width: "100%",
          }}
        >
          {/* Picture on the Left */}
          <div
            style={{
              //   flex: "0 0 100px", // Fixed size for the image
              marginRight: "10px",
            }}
          >
            <img
              src={`${serverUrl}/${props?.item?.prediction?.imgCoverUrl}`}
              alt="cover"
              style={{
                width: width < 440 ? "100%" : 142,
                objectFit: width < 440 ? "cover" : "contain",
                height: width < 440 ? "auto" : 102,
                borderRadius: "8px",
                filter: isPredictionExpired(props?.item?.expireDate)
                  ? "grayscale(100%)"
                  : "",
              }}
            />
          </div>

          {/* Content in the Center */}
          <div
            style={{
              flex: "1",
              minWidth: "200px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <div style={{ paddingLeft: "10px", fontWeight: "bolder" }}>
              <label>{props?.item?.prediction?.title ?? "-"}</label>
            </div>

            <div style={{ paddingLeft: "10px" }}>
              <label style={{ color: "black" }}>
                วันที่ซื้อคอร์ส{" "}
                {moment(props?.item?.createdAt).format("YYYY-MM-DD HH:mm:ss")} (
                {Number(moment(props?.item?.createdAt).format("YYYY")) + 543})
              </label>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <label style={{ color: "black" }}>
                วันที่หมดอายุ{" "}
                {moment(props?.item?.expireDate).format("YYYY-MM-DD HH:mm:ss")}{" "}
                ({Number(moment(props?.item?.createdAt).format("YYYY")) + 543})
              </label>
            </div>
          </div>

          {/* Button on the Right */}
          <div
            style={{
              // Button container, now aligned to the top
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start", // Align button at the top
            }}
          >
            <Button
              type="primary"
              disabled={
                isPredictionExpired(props?.item?.expireDate) ||
                predictionStatus === "AWAITING_PAYMENT_APPROVAL"
              }
              loading={loading}
              onClick={() => {
                if (!hasCompletePersonalInfo) {
                  setModalActive(true);
                } else {
                  if (!isPredictionExpired(props?.item?.expireDate)) {
                    navigate(
                      "/prediction-open?prediction-id=" +
                        props.item.prediction.predictionId
                    );
                  }
                }
              }}
            >
              <label style={{ cursor: "pointer" }}>เข้าพยากรณ์</label>
            </Button>
          </div>
        </div>

        {/* Footer with expiration message and "extend" button */}
        {isPredictionExpired(props?.item?.expireDate) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              gap: 12,
            }}
          >
            <label>หมดอายุเเล้ว! โปรดต่ออายุ เพื่อใช้งานอีกครั้ง</label>
            <Space size={20}>
              <Button
                type="primary"
                onClick={async () => {
                  // Handle extend prediction logic
                  let findPredictionId = resultBasketOrder?.find(
                    (fnd) => fnd.predictionId === props?.item?.predictionId
                  );
                  if (!findPredictionId) {
                    let result = await getPredictionByIdFetch(
                      props?.item?.predictionId
                    );
                    let tmpArr = [...resultBasketOrder, ...[result]];
                    dispatch(setBasketOrderStateToSuccess(tmpArr));
                    navigate("/payment");
                  }
                }}
                style={{ width: 100 }}
              >
                <label style={{ cursor: "pointer" }}>ต่ออายุ</label>
              </Button>
            </Space>
          </div>
        )}
        {predictionStatus === "AWAITING_PAYMENT_APPROVAL" && (
          <>
            <p style={{ fontSize: 24 }}>
              <strong>คุณได้ซื้อโหรนี้เเล้ว!</strong>
            </p>
            <p style={{ fontSize: 24 }}>ขณะนี้ อยู่ระหว่างตรวจสอบการชำระเงิน</p>
          </>
        )}
      </div>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ fontWeight: 400 }}>
              ข้อมูลส่วนตัวของผู้ใช้ เพื่อใช้ในการคํานวณโหร (กรอกเพียงครั้งเเรก
              ครั้งเดียว!)
            </label>
          </div>
        }
        open={modalActive}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setModalActive(false);
        }}
        okText={
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ paddingLeft: 10, cursor: "pointer" }}>
              ยืนยันข้อมูล
            </label>
          </div>
        }
        cancelText={
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
          </div>
        }
        width={962}
      >
        <Card>
          <Row gutter={[16, 0]}>
            <Col span={24} style={{ marginBottom: 24 }}>
              <span>
                กรอกข้อมูลในเเบบฟอร์มด้านล่างนี้ให้ครบถ้วน หากมีข้อสงสัย
                โทรสอบถามได้ที่ 088-860-9785
              </span>
            </Col>
            <Col xs={24} md={6} xl={6}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="คำนำหน้า"
                  name="prefix"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณาเลือกคำนำหน้า" }]}
                >
                  {/* <Input onBlur={(e) => form.setFieldsValue({ prefix: e.target.value })} /> */}
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="คำนำหน้า"
                    optionFilterProp="children"
                    allowClear
                  >
                    {prefixData.map((val, index) => (
                      <Option key={index} value={val.prefixId}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>

            <Col xs={24} md={9} xl={9}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="ชื่อจริง"
                  name="firstname"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณากรอกชื่อจริง" }]}
                >
                  <Input
                    onBlur={(e) =>
                      form.setFieldsValue({ firstname: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </Col>

            <Col xs={24} md={9} xl={9}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="นามสกุล"
                  name="lastname"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณากรอกนามสกุล" }]}
                >
                  <Input
                    onBlur={(e) =>
                      form.setFieldsValue({ lastname: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 12 }}>
              <Row gutter={[12, 0]}>
                <Col xs={24} sm={12} md={8} lg={5}>
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      name="day"
                      label="วันเกิด"
                      style={{ width: "100%" }}
                      rules={[
                        { required: !isDayNull, message: "กรุณาเลือกวันเกิด" },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="วันเกิด"
                        optionFilterProp="children"
                        allowClear
                        disabled={isDayNull}
                      >
                        {day.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                  <Checkbox
                    onChange={() => {
                      if (!isDayNull) {
                        form.setFieldsValue({ day: 30 });
                      } else {
                        form.setFieldsValue({ day: undefined });
                      }
                      setIsDayNull(!isDayNull);
                    }}
                  >
                    ไม่ทราบวัน
                  </Checkbox>
                </Col>

                <Col xs={24} sm={12} md={8} lg={7}>
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      name="month"
                      label="เดือนเกิด"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: !isMonthNull,
                          message: "กรุณาเลือกเดือนเกิด",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เดือนเกิด"
                        optionFilterProp="children"
                        allowClear
                        disabled={isMonthNull}
                      >
                        {month.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                  <Checkbox
                    onChange={() => {
                      if (!isMonthNull) {
                        form.setFieldsValue({ month: 6 });
                      } else {
                        form.setFieldsValue({ month: undefined });
                      }
                      setIsMonthNull(!isMonthNull);
                    }}
                  >
                    ไม่ทราบเดือน
                  </Checkbox>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      name="year"
                      label="ปีเกิด"
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: "กรุณาเลือกปีเกิด" }]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="ปีเกิด"
                        optionFilterProp="children"
                        allowClear
                      >
                        {year.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      name="time"
                      label="เวลาเกิด"
                      style={{ width: "100%", fontSize: 16 }}
                      // rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                    >
                      <TimePicker
                        showNow={false}
                        placeholder="เวลาเกิด"
                        disabled={isTimeNull}
                        style={{ width: "100%" }}
                        locale={{
                          ...locale,
                          lang: {
                            ...locale.lang,
                            // now: "เวลาปัจจุบัน",
                            ok: "ตกลง",
                          },
                        }}
                        format={"HH:mm"}
                      />
                    </Form.Item>
                    <Checkbox
                      onChange={() => {
                        if (!isTimeNull) {
                          form.setFieldsValue({
                            time: moment("12:00", "HH:mm"),
                          });
                        } else {
                          form.setFieldsValue({ time: undefined });
                        }
                        setIsTimeNull(!isTimeNull);
                      }}
                    >
                      ไม่ทราบเวลา
                    </Checkbox>
                  </Form>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <label>
                เมื่อกรอกข้อมูลในเเบบฟอร์มครบถ้วนเเล้ว คลิกที่ปุ่ม
                “ยืนยันข้อมูล” เพื่อยืนยันข้อมูล
                <br />
                เเละไปขั้นตอนถัดไปในการสั่งซื้อโหร
              </label>
            </Col>
          </Row>
        </Card>
      </Modal>
    </Col>
  );
}
