export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING"
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS"
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

// export const serverUrl = "http://localhost:3001"
// export const serverUrl = "https://cdn.witte-bangkok.com"
export const serverUrl = "https://production-witte-bangkok-phase2-server.onrender.com"

export const imgDefaultUrl = `./assets/images/default/df-img.png`

export const apiServerUrl = serverUrl + "/api/v1"

export const server = {}
