"use client"
import { Card, Col, Empty, Pagination, Row } from "antd"
import { useEffect, useState } from "react"

import Meta from "antd/es/card/Meta"
import { useNavigate } from "react-router"
import LoadingPage from "../../../../common/components/loading/page"
import { serverUrl } from "../../../../constants"
import axios from "axios"
import "./css/index.css"
import "./css/paginate.css"

const ShotOfIdeas = (props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [ideas, setIdeas] = useState([])
  const perPage = 6 // Number of items per page

  const navigate = useNavigate()

  const getShotOfIdeas = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${serverUrl}/api/v1/blog/get-blog-all`)
      const data = response.data

      if (data.isSuccess) {
        const result = data.formData
        setTotal(result.length) // Set total count for pagination
        setIdeas(result) // Store all ideas data
      }
    } catch (error) {
      console.error("Error fetching shot of ideas:", error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getShotOfIdeas() // Fetch data on component mount
  }, [])

  const onChange = (page) => {
    setCurrentPage(page) // Update the current page
  }

  if (isLoading) return <LoadingPage />

  // Slice the ideas array to show only the current page's ideas
  const currentIdeas = ideas.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  )

  return (
    <div className="shot-of-ideas">
      <h2
        style={{
          fontSize: 36,
          textAlign: "center",
          paddingBottom: 37,
          color: "#000",
        }}
      >
        เเหล่งความรู้
      </h2>
      <div className="container">
        <Row
          gutter={[24, 24]}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {currentIdeas.length > 0 ? (
            currentIdeas.map((idea, index) => (
              <Col xs={24} sm={12} lg={8} key={idea.blogId}>
                <Card
                  className={`i${index % 4}`}
                  hoverable
                  style={{
                    fontSize: "18px",
                    padding: "0",
                  }}
                  cover={
                    <img
                      alt="truck"
                      src={`${serverUrl}/${idea.imageCoverUrl}`}
                      width={352}
                      height={205}
                      style={{
                        maxWidth: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  onClick={() => {
                    navigate(`/blog/${idea.blogId.toString()}`);
                    window.scroll(0, 0);
                  }}
                >
                  <Meta
                    title={
                      <div
                        style={{
                          fontSize: "24px",
                          paddingInline: "0px",
                          marginTop: "6px",
                        }}
                      >
                        {idea.title}
                      </div>
                    }
                    description={
                      <div
                        style={{
                          color: "#000",
                          padding: "0px",
                          display: "flex",
                          flexDirection: "column",
                          height: 140,
                        }}
                      >
                        <div className="blog-detail">{idea.subTitle}</div>
                        <div style={{ textDecoration: "underline" }}>
                          อ่านเพิ่มเติม
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ textAlign: "center" }}
            />
          )}
        </Row>
        {ideas.length > 0 && (
          <Row className="paginate-container">
            <Pagination
              size="small"
              current={currentPage}
              total={total}
              pageSize={perPage}
              onChange={onChange}
            />
          </Row>
        )}
      </div>
    </div>
  );
};

export default ShotOfIdeas;
