import React from "react";
import "./css/card.css";
import { Button } from "antd";
import { serverUrl } from "../../../../../constants";
import parse from "html-react-parser";
import { useDimensions } from "../../../../../common/mamager/ScreenManager";

const Card = ({ service, isSmall }) => {
  const { width } = useDimensions();

  return (
    <div
      className="our-service-card"
      onClick={() => {
        if (width <= 440 && service.externalLinkUrl) {
          window.location.href = service.externalLinkUrl;
        }
      }}
    >
      <div className={`wrapper ${isSmall ? "small" : ""}`}>
        <div className="front-card">
          <div className="section-image">
            <img
              src={`${serverUrl}/${service.imageUrl}`}
              alt="service1"
              style={{ maxWidth: "100%" }}
              height={isSmall ? 137 : 264}
            />
          </div>
          <div className="section-text">
            <h3
              style={{
                textAlign: "center",
                color: "#FEE285",
                fontSize: isSmall ? 20 : 32,
              }}
            >
              {service.title}
            </h3>

            {parse(service.subTitle)}
          </div>
        </div>
        {width > 440 && (
          <div
            className="overlay"
            // onClick={() => router.push(`/our-service/${service.id}`)}
          >
            {service.externalLinkUrl && (
              <div className="text" style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  onClick={() => window.open(service.externalLinkUrl)}
                  size="large"
                >
                  คลิกเลย
                </Button>

                <p style={{ marginTop: 8 }}>เพื่อเข้าชมรายละเอียด</p>
              </div>
            )}

            {!service.externalLinkUrl && (
              <div className="text" style={{ textAlign: "center" }}>
                <p>ติดต่อเลย ที่นี่!</p>
                <div
                  style={{
                    marginTop: 8,
                    backgroundColor: "#F3BB02",
                    borderRadius: 8,
                    padding: 8,
                    fontWeight: "bolder",
                  }}
                >
                  088-860-9785
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
