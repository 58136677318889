/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */

import { useEffect, useState } from "react"
import { Button, Col, Row } from "antd"
import axios from "axios"
import LoadingPage from "../../common/components/loading/page"
import Footer from "../../common/components/footer"
import BreadcrumbPage from "../../common/components/breadcrumbPage"
import Header from "../../common/components/header"
import { serverUrl } from "../../constants"
import parse from "html-react-parser"
import "./css/index.css"
import {
  useParams,
  // useNavigate
} from "react-router"

// import { useDispatch } from "react-redux"
// import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action"
// import parse from "html-react-parser"

const EBookDetail = ({ firebase, user, authorized }) => {
  const [productDetail, setProductDetail] = useState()

  //Comment set isLoading to false
  const [isLoading, setIsLoading] = useState(true)

  // const dispatch = useDispatch()
  // const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const getProductDetail = async () => {
      try {
        setIsLoading(true)

        // Fetch product detail
        const res = await axios.get(
          `${serverUrl}/api/v1/ebook/get-ebook-by-id/${id}`
        )

        const { data } = res

        if (data.isSuccess) {
          setProductDetail(data.formData[0])
        }

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching e-book details:", error)
      }
    }
    getProductDetail()
  }, [])

  const handleBuyProduct = () => {
    // console.log("productDetail --- : ", productDetail?.link)
    window.open(productDetail?.link)

    // window.scrollTo(0, 0)
    // navigate("/payment")
    // const eBook = [productDetail]
    // dispatch(setBasketOrderStateToSuccess(eBook))
  }

  if (isLoading) return <LoadingPage loading={isLoading} />

  return (
    <div className="ebook-detail">
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={productDetail.title} />
      <section className="detail">
        <div className="main-bg">
          <div className="container">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <Col xs={24} md={24} lg={12}>
                <img
                  src={`${serverUrl}/${productDetail.imgCoverUrl}`}
                  width={426}
                  height={467}
                  alt="product"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover"
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={12}>
                <div className="detail-text">
                  {/* <div className="price">
                    <div className="title">ราคา</div>
                    <div>
                      <span
                        style={{
                          textDecoration:
                            productDetail?.discount > 0
                              ? "line-through"
                              : "none",
                          textDecorationColor:
                            productDetail?.discount > 0
                              ? "#FF0000"
                              : "transparent",
                          color:
                            productDetail?.discount > 0 ? "#F3BB02" : "#FEE285"
                        }}
                      >
                        {new Intl.NumberFormat("en-US").format(
                          productDetail?.price
                        )}
                      </span>
                      <span> บาท</span>
                    </div>
                  </div> */}

                  {/* {productDetail?.discount > 0 && (
                    <div className="price">
                      <div className="title">ลดเหลือ</div>
                      <div className="highlight">
                        {new Intl.NumberFormat("en-US").format(
                          productDetail?.price - productDetail?.discount
                        )}
                        <span style={{ color: "white" }}> บาท</span>
                      </div>
                    </div>
                  )} */}

                  <div style={{ display: "grid" }}>
                    {productDetail?.promotion && (
                      <div style={{ color: "white", fontSize: 24}}>
                        <strong>โปรโมชั่น</strong>
                        <br />
                        <div style={{ fontSize: 20 }}>
                          {parse(productDetail?.promotion)}
                        </div>
                      </div>
                    )}
                  </div>

                  <Button
                    size="large"
                    type="primary"
                    style={{ marginTop: 24 }}
                    onClick={handleBuyProduct}
                  >
                    สังซื้อเลย
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="container">
          {productDetail?.detail && (
            <div className="suggest">
              <strong>คำแนะนำ</strong>
              <br />
              <div style={{ fontSize: 20 }}>{parse(productDetail?.detail)}</div>
            </div>
          )}

          {productDetail?.pdfUrl && (
            <div className="promotion">
              <strong>ตัวอย่าง eBook</strong>
              <br />
              <iframe
                src={`${serverUrl}/${productDetail?.pdfUrl}`}
                style={{ width: "100%", height: 900 }}
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default EBookDetail;
